@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
@import url(//fonts.googleapis.com/icon?family=Material+Icons);
body {
  font-family: 'Noto Sans TC', sans-serif;
  margin: 0;
}

* {
  outline: none !important;
}

button:focus {
  outline: none !important;
}

::-webkit-scrollbar {
  width: 0;
}

.mat-dialog-content {
  overflow-x: scroll;
}

mat-sidenav-content::-webkit-scrollbar,
.contactbox::-webkit-scrollbar,
.shopInfo::-webkit-scrollbar,
.mat-dialog-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.contactbox::-webkit-scrollbar-thumb,
.shopInfo::-webkit-scrollbar-thumb,
.mat-dialog-content::-webkit-scrollbar-thumb {
  border-radius: 100px;
}

.contactbox::-webkit-scrollbar-track,
.shopInfo::-webkit-scrollbar-track,
.mat-dialog-content::-webkit-scrollbar-track {
  border-radius: 0;
}

.contactbox:hover::-webkit-scrollbar-thumb,
.shopInfo:hover::-webkit-scrollbar-thumb,
.mat-dialog-content:hover::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.8);
}

.contactbox:hover::-webkit-scrollbar-track,
.shopInfo:hover::-webkit-scrollbar-track,
.mat-dialog-content:hover::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.fadein {
  -moz-animation: fadein 1s;
  -webkit-animation: fadein 1s;
  animation: fadein 1s;
}

.k-form fieldset {
  border-width: 0px 0 0 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #11688b;
}

.mat-dialog-container {
  overflow-y: scroll;
  max-height: 80vh;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.priority_high {
  color: gray;
  position: relative;
}

button .priority_high {
  top: 0px;
}

#_console_report .mat-progress-bar-fill::after {
  background-color: #fee163;
}

#_console_report .mat-progress-bar-buffer {
  background-color: #ecf0f3;
}

.fadein .mat-tab-label {
  opacity: 1 !important;
}

iframe#launcher {
  position: fixed;
  top: 0 !important;
  right: 50px !important;
  left: unset;
}

.doublecraneMemberNumber .mat-form-field-infix {
  border-top: 0;
}

.survey-set-stepper .mat-horizontal-stepper-header-container {
  width: 60%;
  margin: auto;
}

.deltaPoints .mat-form-field-flex {
  align-items: center;
  overflow: hidden;
}

.deltaPoints .mat-form-field-flex button {
  height: 60px;
  border-right: 2px solid #e0e0e0;
  border-left: 2px solid #e0e0e0;
  border-radius: 0;
}

.deltaPoints .mat-form-field-flex:hover button {
  border-color: black;
  transition: 0.4s;
}

.position-sticky-father mat-tab-header {
  position: sticky;
  top: 68px;
  z-index: 999;
  background: #fff;
}

.angularEditorBox button#foregroundColorPicker- {
  background-image: url('/assets/images/article/color.png');
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: #ffffff00;
  border: 0;
  position: relative;
  left: 165px;
  top: 1px;
}

.angularEditorBox button#foregroundColorPicker- span {
  visibility: hidden;
}

.angularEditorBox .angular-editor {
  display: flex;
  flex-direction: column-reverse;
}

.angularEditorBox .angular-editor-toolbar {
  background-color: transparent !important;
  border: 0 !important;
  display: flex;
  flex-direction: row-reverse;
}

.angularEditorBox input[type='color'] {
  width: 0.1px;
  height: 0.1px;
  display: inline !important;
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
  left: 30px;
  top: 20px;
}

.angularEditorBox .angular-editor-toolbar-set {
  position: relative;
  left: -20px;
}

/* 彈性區塊編輯器 */
app-action-area .mat-form-field-underline {
  background-color: white !important;
}

app-action-area .mat-form-field-label .ng-star-inserted {
  color: white !important;
}

/* 客服管理 V2 */
.customer-v2-editor-main .flexibility .mat-form-field-wrapper,
.customer-v2-editor-main .flexibility .mat-form-field-flex,
.customer-v2-editor-main .flexibility mat-form-field {
  height: 100%;
}

app-customer-v2 mat-drawer-content {
  position: relative;
  top: calc(50% - 20px);
  max-height: 40px;
}

app-detail-analytics .time-distribution-card .mat-tab-label {
  /* width: calc(100% / 3); */
  width: calc(100% / 2);
  min-width: unset;
}

.select-check-all .mat-checkbox-layout,
.select-check-all .mat-checkbox-label {
  width: 100% !important;
}

:root {
  --black: #000000;
  --white: #ffffff;
  --gray: #5e5e5e;
  --gray-border: #dadce0;
  --gray-shadow: #bab8b8;
  --purple: #6b62ab;
  --red: #ed6e57;
}
